export const ProactivityIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20">
            <path d="M12.0937 12.6875C12.0391 12.6719 11.9805 12.668 11.9258 12.6758C11.918 12.6758 11.9141 12.6758 11.9062 12.6758C12.4141 12.1797 12.7305 11.4883 12.7305 10.7266V6.83594C12.7305 6.62109 12.5547 6.44531 12.3398 6.44531H9.44531C8.24999 6.44531 7.27734 7.41797 7.27734 8.61328V10.7266C7.27734 11.4883 7.59375 12.1797 8.10156 12.6758C8.09374 12.6758 8.08984 12.6758 8.08203 12.6758C8.02734 12.668 7.96875 12.6719 7.91406 12.6875C5.89453 12.8672 4.30469 14.5703 4.30469 16.6367V18.5547C4.30469 18.7695 4.48047 18.9453 4.69531 18.9453H15.3125C15.5273 18.9453 15.7031 18.7695 15.7031 18.5547V16.6367C15.6992 14.5703 14.1133 12.8711 12.0937 12.6875ZM8.05468 9.16016V8.61328C8.05468 7.84766 8.67578 7.22656 9.4414 7.22656H11.9453V8.27344C11.9453 8.80078 11.5156 9.23047 10.9883 9.23047H8.05468V9.16016ZM8.05468 10.7266V10.0117H10.9883C11.3437 10.0117 11.6719 9.90625 11.9453 9.72266V10.7266C11.9453 11.8008 11.0742 12.6719 9.99999 12.6719C8.92578 12.6719 8.05468 11.8008 8.05468 10.7266ZM11.0391 13.4531L9.99999 14.4922L8.96093 13.4531H11.0391ZM14.918 18.1641H5.08203V17.0391H8.26953C8.48437 17.0391 8.66015 16.8633 8.66015 16.6484C8.66015 16.4336 8.48437 16.2578 8.26953 16.2578H5.10547C5.27734 14.8047 6.42968 13.6523 7.88281 13.4766L9.72656 15.3203C9.80468 15.3984 9.90234 15.4336 10.0039 15.4336C10.1055 15.4336 10.2031 15.3945 10.2812 15.3203L12.125 13.4766C13.6992 13.668 14.9258 15.0117 14.9258 16.6367V18.1641H14.918Z" fill="url(#paint0_linear)"/>
            <path d="M10.2773 16.3711C10.2031 16.2969 10.1055 16.2578 10 16.2578C9.89453 16.2578 9.79687 16.3008 9.72266 16.3711C9.64844 16.4414 9.60938 16.543 9.60938 16.6484C9.60938 16.7539 9.65234 16.8516 9.72266 16.9258C9.79687 17 9.89453 17.0391 10 17.0391C10.1055 17.0391 10.2031 16.9961 10.2773 16.9258C10.3516 16.8516 10.3906 16.7539 10.3906 16.6484C10.3906 16.543 10.3477 16.4453 10.2773 16.3711Z" fill="url(#paint1_linear)"/>
            <path d="M10.0039 1.05469C9.18359 1.05469 8.51953 1.71875 8.51953 2.53906C8.51953 3.22266 8.98438 3.80078 9.61328 3.97266V4.92578C9.61328 5.14062 9.78906 5.31641 10.0039 5.31641C10.2188 5.31641 10.3945 5.14062 10.3945 4.92578V3.97266C11.0234 3.80078 11.4883 3.22266 11.4883 2.53906C11.4883 1.71875 10.8242 1.05469 10.0039 1.05469ZM10.0039 3.24219C9.61719 3.24219 9.30078 2.92578 9.30078 2.53906C9.30078 2.15234 9.61719 1.83594 10.0039 1.83594C10.3906 1.83594 10.707 2.15234 10.707 2.53906C10.707 2.92578 10.3945 3.24219 10.0039 3.24219Z" fill="url(#paint2_linear)"/>
            <path d="M17.8477 4.10156C17.5664 3.82031 17.1953 3.66797 16.7969 3.66797C16.3984 3.66797 16.0273 3.82422 15.7461 4.10156C15.2617 4.58594 15.1836 5.32031 15.5078 5.88671L14.8359 6.56249C14.6836 6.71484 14.6836 6.96093 14.8359 7.11327C14.9141 7.1914 15.0117 7.22655 15.1133 7.22655C15.2148 7.22655 15.3125 7.18749 15.3906 7.11327L16.0664 6.43749C16.2891 6.5664 16.5391 6.63281 16.8047 6.63281C17.1992 6.63281 17.5742 6.47656 17.8555 6.19921C18.1367 5.91796 18.2891 5.54687 18.2891 5.14843C18.2891 4.75 18.1289 4.38281 17.8477 4.10156ZM17.293 5.64843C17.0195 5.92187 16.5742 5.92187 16.2969 5.64843C16.0234 5.375 16.0234 4.92968 16.2969 4.65234C16.4336 4.51562 16.6133 4.44531 16.793 4.44531C16.9727 4.44531 17.1524 4.51562 17.2891 4.65234C17.4219 4.78515 17.4961 4.96093 17.4961 5.14843C17.5 5.33984 17.4258 5.51562 17.293 5.64843Z" fill="url(#paint3_linear)"/>
            <path d="M18.5156 10.4609C17.832 10.4609 17.2539 10.9258 17.082 11.5547H16.1289C15.9141 11.5547 15.7383 11.7305 15.7383 11.9453C15.7383 12.1602 15.9141 12.3359 16.1289 12.3359H17.082C17.2539 12.9648 17.832 13.4297 18.5156 13.4297C19.3359 13.4297 20 12.7656 20 11.9453C20 11.125 19.3359 10.4609 18.5156 10.4609ZM18.5156 12.6484C18.1289 12.6484 17.8125 12.332 17.8125 11.9453C17.8125 11.5586 18.1289 11.2422 18.5156 11.2422C18.9023 11.2422 19.2188 11.5586 19.2188 11.9453C19.2188 12.332 18.9023 12.6484 18.5156 12.6484Z" fill="url(#paint4_linear)"/>
            <path d="M14.9258 11.6719C14.8516 11.5977 14.7539 11.5586 14.6484 11.5586C14.543 11.5586 14.4453 11.6016 14.3711 11.6719C14.2969 11.7461 14.2578 11.8438 14.2578 11.9492C14.2578 12.0508 14.3008 12.1523 14.3711 12.2266C14.4453 12.3008 14.543 12.3398 14.6484 12.3398C14.7539 12.3398 14.8516 12.2969 14.9258 12.2266C15 12.1523 15.0391 12.0547 15.0391 11.9492C15.0391 11.8438 14.9961 11.7422 14.9258 11.6719Z" fill="url(#paint5_linear)"/>
            <path d="M3.87109 11.5469H2.91797C2.74609 10.918 2.16797 10.4531 1.48438 10.4531C0.664062 10.4531 0 11.1172 0 11.9375C0 12.7578 0.664062 13.4219 1.48438 13.4219C2.16797 13.4219 2.74609 12.957 2.91797 12.3281H3.87109C4.08594 12.3281 4.26172 12.1523 4.26172 11.9375C4.26172 11.7227 4.08594 11.5469 3.87109 11.5469ZM1.48438 12.6406C1.09766 12.6406 0.78125 12.3242 0.78125 11.9375C0.78125 11.5508 1.09766 11.2344 1.48438 11.2344C1.87109 11.2344 2.1875 11.5508 2.1875 11.9375C2.1875 12.3242 1.87109 12.6406 1.48438 12.6406Z" fill="url(#paint6_linear)"/>
            <path d="M5.14062 6.52344L4.46484 5.84766C4.59375 5.625 4.66016 5.375 4.66016 5.10938C4.66016 4.71484 4.50391 4.33984 4.22656 4.05859C3.94531 3.77734 3.57422 3.625 3.17578 3.625C2.77734 3.625 2.40625 3.78125 2.125 4.05859C1.54688 4.63672 1.54688 5.57813 2.125 6.15625C2.40625 6.4375 2.77734 6.58984 3.17578 6.58984C3.4375 6.58984 3.69141 6.52344 3.91406 6.39453L4.58984 7.07031C4.66797 7.14844 4.76562 7.18359 4.86719 7.18359C4.96875 7.18359 5.06641 7.14453 5.14453 7.07031C5.29297 6.92578 5.29297 6.67578 5.14062 6.52344ZM3.67578 5.60938C3.40234 5.88281 2.95703 5.88281 2.67969 5.60938C2.40625 5.33594 2.40625 4.89062 2.67969 4.61328C2.81641 4.47656 2.99609 4.40625 3.17578 4.40625C3.35547 4.40625 3.53516 4.47656 3.67187 4.61328C3.80469 4.74609 3.87891 4.92188 3.87891 5.10938C3.88281 5.30078 3.80859 5.47656 3.67578 5.60938Z" fill="url(#paint7_linear)"/>
            <defs>
                <linearGradient id="paint0_linear" x1="4.30039" y1="12.6953" x2="15.6998" y2="12.6953" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="9.60938" y1="16.6492" x2="10.3906" y2="16.6492" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="8.52059" y1="3.18512" x2="11.4893" y2="3.18512" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="14.7197" y1="5.44789" x2="18.2818" y2="5.44789" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="15.7391" y1="11.9466" x2="20" y2="11.9466" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="14.2578" y1="11.9465" x2="15.0391" y2="11.9465" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint6_linear" x1="0" y1="11.9367" x2="4.26086" y2="11.9367" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint7_linear" x1="1.69527" y1="5.41023" x2="5.25663" y2="5.41023" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
            </defs>
        </svg>

    )
}