import React from "react";

const AnalyticsIcon = (props) => {
    return (
        <svg viewBox="0 0 20 20" {...props}>
            <g clipPath="url(#clip0)">
                <path d="M2.99941 14.3331H0.999805C0.449131 14.3331 0 14.7823 0 15.3329V19.6641C0 19.8477 0.148409 19.9961 0.331966 19.9961H3.66335C3.84691 19.9961 3.99531 19.8477 3.99531 19.6641V15.3329C3.99922 14.7823 3.55399 14.3331 2.99941 14.3331ZM3.33529 19.3322H0.667838V15.3329C0.667838 15.1494 0.816247 15.001 0.999805 15.001H2.99941C3.18297 15.001 3.33138 15.1494 3.33138 15.3329L3.33529 19.3322Z" fill="url(#paint0_linear)"/>
                <path d="M8.33431 9.66614H6.3347C5.78403 9.66614 5.3349 10.1153 5.3349 10.6659V19.6642C5.3349 19.8477 5.48331 19.9962 5.66687 19.9962H8.99824C9.1818 19.9962 9.33021 19.8477 9.33021 19.6642V10.6659C9.33412 10.1153 8.88498 9.67004 8.33431 9.66614ZM8.66628 19.3322H5.99883V10.6659C5.99883 10.4824 6.14724 10.334 6.3308 10.334H8.33041C8.51396 10.334 8.66237 10.4824 8.66237 10.6659L8.66628 19.3322Z" fill="url(#paint1_linear)"/>
                <path d="M13.6652 11.6656H11.6656C11.115 11.6656 10.6658 12.1148 10.6658 12.6655V19.6641C10.6658 19.8476 10.8142 19.996 10.9978 19.996H14.3331C14.5166 19.996 14.6651 19.8476 14.6651 19.6641V12.6655C14.6651 12.1148 14.2159 11.6696 13.6652 11.6656ZM13.9972 19.3321H11.3298V12.6655C11.3298 12.4819 11.4782 12.3335 11.6617 12.3335H13.6613C13.8449 12.3335 13.9933 12.4819 13.9933 12.6655L13.9972 19.3321Z" fill="url(#paint2_linear)"/>
                <path d="M18.9962 9.0022H16.9966C16.446 9.0022 15.9968 9.45133 15.9968 10.002V19.6681C15.9968 19.8516 16.1452 20.0001 16.3288 20.0001H19.6602C19.8437 20.0001 19.9921 19.8516 19.9921 19.6681V10.002C19.996 9.44742 19.5508 9.0022 18.9962 9.0022ZM19.3321 19.3322H16.6647V10.002C16.6647 9.81844 16.8131 9.67004 16.9966 9.67004H18.9962C19.1798 9.67004 19.3282 9.81844 19.3282 10.002L19.3321 19.3322Z" fill="url(#paint3_linear)"/>
                <path d="M16.5031 3.99414C17.1124 4.6815 18.1161 4.86506 18.9284 4.43546C19.7408 4.00585 20.1586 3.08025 19.936 2.1859C19.7134 1.29154 18.9128 0.666663 17.995 0.666663C17.3272 0.666663 16.7023 0.99863 16.3313 1.55321C15.9602 2.10779 15.8899 2.81078 16.1438 3.42784L14.152 4.67369C13.7927 4.26752 13.285 4.02538 12.7421 4.00195C12.1993 3.97852 11.672 4.1777 11.2815 4.55262L9.09831 2.91622C9.55915 2.02577 9.28577 0.928331 8.46171 0.354224C7.63765 -0.219882 6.51678 -0.0910011 5.84113 0.651041C5.16548 1.39308 5.14595 2.52177 5.79817 3.28725L3.00575 6.60691C2.11139 6.07967 0.95927 6.32571 0.357825 7.17711C-0.24362 8.02851 -0.0913058 9.19234 0.701508 9.86018C1.49432 10.528 2.67378 10.4772 3.40411 9.73911C4.13443 9.00097 4.1852 7.82542 3.50955 7.03261L6.30198 3.71294C7.06745 4.16989 8.04773 4.05663 8.69214 3.43956L10.8753 5.07596C10.4496 5.90002 10.6449 6.90373 11.3479 7.50908C12.0509 8.11443 13.0741 8.15739 13.8239 7.61453C14.5738 7.07166 14.855 6.08748 14.4996 5.23218L16.5031 3.99414ZM17.995 1.3345C18.7331 1.3345 19.3268 1.93204 19.3268 2.66627C19.3268 3.4005 18.7292 3.99804 17.995 3.99804C17.2569 3.99804 16.6632 3.4005 16.6632 2.66627C16.6632 1.93204 17.2608 1.3345 17.995 1.3345ZM1.99813 9.66491C1.25999 9.66491 0.666359 9.06737 0.666359 8.33313C0.666359 7.5989 1.2639 7.00136 1.99813 7.00136C2.73236 7.00136 3.3299 7.5989 3.3299 8.33313C3.3299 9.06737 2.73627 9.66491 1.99813 9.66491ZM7.33303 3.33411C6.59489 3.33411 6.00125 2.73657 6.00125 2.00234C6.00125 1.26811 6.59879 0.670569 7.33303 0.670569C8.06726 0.670569 8.6648 1.26811 8.6648 2.00234C8.6648 2.73657 8.06726 3.33411 7.33303 3.33411ZM12.664 7.33333C11.9259 7.33333 11.3322 6.73579 11.3322 6.00156C11.3322 5.26733 11.9298 4.66979 12.664 4.66979C13.4022 4.66979 13.9958 5.26733 13.9958 6.00156C13.9958 6.73579 13.3982 7.33333 12.664 7.33333Z" fill="url(#paint4_linear)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="0.00132781" y1="17.166" x2="4.00051" y2="17.166" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="5.33361" y1="14.8332" x2="9.33279" y2="14.8332" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="10.6658" y1="15.8329" x2="14.665" y2="15.8329" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="15.9982" y1="14.5" x2="19.9973" y2="14.5" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="-0.000194552" y1="5.16728" x2="19.9961" y2="5.16728" gradientUnits="userSpaceOnUse">
                    <stop offset="2.02456e-07" stopColor="#8116B2"/>
                    <stop offset="1" stopColor="#C32D54"/>
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default AnalyticsIcon;