import React from "react";

const VkIcon = (props) => {
   return ( <svg {...props} viewBox="0 0 37 37">
        <g clipPath="url(#clip2)">
            <path d="M18.7 33.4C27.5366 33.4 34.7 26.8186 34.7 18.7C34.7 10.5814 27.5366 4 18.7 4C9.86344 4 2.7 10.5814 2.7 18.7C2.7 26.8186 9.86344 33.4 18.7 33.4Z" fill="white"/>
            <path d="M18.1 0C8.1 0 0 8.1 0 18.1C0 28.1 8.1 36.2 18.1 36.2C28.1 36.2 36.2 28.1 36.2 18.1C36.2 8.1 28.1 0 18.1 0ZM27.3 20.1C28.1 20.9 29 21.7 29.8 22.6C30.1 23 30.5 23.4 30.7 23.9C31 24.6 30.7 25.3 30.1 25.3H26.4C25.4 25.4 24.7 25 24 24.3C23.5 23.8 23 23.2 22.5 22.7C22.3 22.5 22.1 22.3 21.8 22.1C21.3 21.8 20.9 21.9 20.6 22.4C20.3 22.9 20.2 23.5 20.2 24.1C20.2 25 19.9 25.2 19 25.2C17.1 25.3 15.3 25 13.7 24.1C12.2 23.3 11.1 22.1 10.1 20.8C8.2 18.2 6.7 15.4 5.4 12.4C5.1 11.7 5.3 11.4 6 11.4C7.2 11.4 8.4 11.4 9.6 11.4C10.1 11.4 10.4 11.7 10.6 12.2C11.3 13.8 12.1 15.3 13.1 16.8C13.4 17.2 13.6 17.6 14 17.8C14.4 18.1 14.8 18 15 17.5C15.1 17.2 15.2 16.9 15.2 16.5C15.3 15.4 15.3 14.3 15.1 13.1C15 12.4 14.6 12 13.9 11.8C13.5 11.7 13.6 11.6 13.8 11.4C14.1 11.1 14.4 10.8 14.9 10.8H19.1C19.8 10.9 19.9 11.2 20 11.9V16.6C20 16.9 20.1 17.6 20.6 17.8C21 17.9 21.2 17.6 21.4 17.4C22.4 16.3 23.1 15.1 23.8 13.8C24.1 13.2 24.3 12.6 24.6 12C24.8 11.6 25.1 11.3 25.5 11.4H29.5C29.6 11.4 29.7 11.4 29.9 11.4C30.6 11.5 30.8 11.8 30.6 12.5C30.3 13.5 29.6 14.4 29 15.3C28.3 16.2 27.6 17.1 26.9 18.1C26.5 18.9 26.5 19.3 27.3 20.1Z" fill="url(#lgrad)"/>
        </g>
        <defs>
            <linearGradient id="lgrad" x1="0%" y1="0" x2="100%" y2="0" >
                <stop offset="0%" stopColor='rgb(153,15,183)' stopOpacity={1} />
                <stop offset="50%" stopColor='rgb(189,28,136)' stopOpacity={1} />
                <stop offset="100%" stopColor='rgb(227,41,86)' stopOpacity={1} />
            </linearGradient>
            <clipPath id="clip2">
                <rect width="36.2" height="36.2" fill="white"/>
            </clipPath>
        </defs>
    </svg>)
};

export default VkIcon;


