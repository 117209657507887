export const HumanityLoveIcon = (props) => {
    return (<svg {...props} viewBox="0 0 20 20" >
                <path d="M15.7344 4.14062C15.7344 2.78125 14.8438 0.625 12.457 0.625C11.4766 0.625 10.6523 1.08594 9.99609 2C9.34766 1.08594 8.52344 0.625 7.54297 0.625C5.15625 0.625 4.26562 2.78125 4.26562 4.14062C4.26562 6.48438 6.09375 7.94922 8.59375 10.0859C8.96094 10.3984 9.33984 10.7227 9.74609 11.0742C9.82031 11.1367 9.91016 11.168 10.0039 11.168C10.0938 11.168 10.1875 11.1367 10.2617 11.0742C10.668 10.7227 11.0469 10.3984 11.4141 10.0859C14.0234 7.85156 15.7344 6.43359 15.7344 4.14062ZM10 10.2656C9.6875 9.99609 9.39062 9.74219 9.10156 9.49219C6.48828 7.25781 5.04688 6.02734 5.04688 4.14062C5.04688 2.86328 5.88281 1.40625 7.54297 1.40625C8.41016 1.40625 9.10156 1.90234 9.66016 2.92578C9.73047 3.05078 9.85938 3.12891 10.0039 3.12891C10.1484 3.12891 10.2773 3.05078 10.3477 2.92578C10.9062 1.90234 11.5977 1.40625 12.4648 1.40625C14.1016 1.40625 14.9609 2.83984 14.9609 4.14062C14.9609 6.02734 13.5195 7.25781 10.9063 9.49219C10.6094 9.74219 10.3125 9.99609 10 10.2656Z" fill="url(#paint0_linear)"/>
                <path d="M2.80859 15.9414L4.6875 16.8828V18.9844C4.6875 19.1992 4.86328 19.375 5.07812 19.375H9.21875C9.43359 19.375 9.60938 19.1992 9.60938 18.9844V13.8867C9.60938 12.6914 8.71094 11.6992 7.52344 11.5781L7.49219 11.5742C6.80469 11.5039 5.58594 11.0352 5.10547 10.5547L4.22656 9.67969C3.93359 9.38281 3.54297 9.21875 3.125 9.21875V6.09375C3.125 5.23047 2.42578 4.53125 1.5625 4.53125C0.699219 4.53125 0 5.23047 0 6.09375V11.3984C0 13.3242 1.08594 15.082 2.80859 15.9414ZM0.78125 6.09375C0.78125 5.66406 1.13281 5.3125 1.5625 5.3125C1.99219 5.3125 2.34375 5.66406 2.34375 6.09375V9.42969C1.86719 9.70703 1.5625 10.2188 1.5625 10.7812C1.5625 11.1992 1.72656 11.5898 2.01953 11.8867L4.01953 13.8867C4.17188 14.0391 4.41797 14.0391 4.57031 13.8867C4.72266 13.7344 4.72266 13.4883 4.57031 13.3359L2.57031 11.3359C2.42188 11.1875 2.33984 10.9922 2.33984 10.7852C2.33984 10.4531 2.55078 10.1602 2.85938 10.0469C3.12891 9.95313 3.44922 10.0078 3.67188 10.2305L4.55078 11.1055C5.45703 12.0117 7.43359 12.3516 7.4375 12.3516C8.22656 12.4297 8.82422 13.0898 8.82422 13.8828V18.5938H5.46484V16.6406C5.46484 16.4922 5.38281 16.3555 5.25 16.293L3.15234 15.2461C1.69141 14.5117 0.78125 13.0391 0.78125 11.3984V6.09375Z" fill="url(#paint1_linear)"/>
                <path d="M17.9297 15.3906C18.1454 15.3906 18.3203 15.2157 18.3203 15C18.3203 14.7843 18.1454 14.6094 17.9297 14.6094C17.714 14.6094 17.5391 14.7843 17.5391 15C17.5391 15.2157 17.714 15.3906 17.9297 15.3906Z" fill="url(#paint2_linear)"/>
                <path d="M18.4375 4.53125C17.5742 4.53125 16.875 5.23047 16.875 6.09375V9.21875C16.457 9.21875 16.0664 9.38281 15.7695 9.67578L14.8906 10.5547C14.4102 11.0352 13.1914 11.5039 12.5039 11.5742L12.4766 11.5781C11.2852 11.6992 10.3867 12.6914 10.3867 13.8867V18.9844C10.3867 19.1992 10.5625 19.375 10.7773 19.375H14.918C15.1328 19.375 15.3086 19.1992 15.3086 18.9844V16.8828L16.5859 16.2422C16.7773 16.1445 16.8555 15.9102 16.7617 15.7188C16.6641 15.5273 16.4297 15.4492 16.2383 15.543L14.7422 16.2891C14.6094 16.3555 14.5273 16.4922 14.5273 16.6367V18.5898H11.168V13.8867C11.168 13.0938 11.7617 12.4336 12.5547 12.3555L12.582 12.3516C13.4297 12.2656 14.832 11.7148 15.4414 11.1055L16.3203 10.2266C16.543 10.0039 16.8633 9.94531 17.1328 10.043C17.4453 10.1523 17.6523 10.4492 17.6523 10.7812C17.6523 10.9883 17.5703 11.1875 17.4219 11.332L15.4219 13.332C15.2695 13.4844 15.2695 13.7305 15.4219 13.8828C15.5742 14.0352 15.8203 14.0352 15.9726 13.8828L17.9726 11.8828C18.2695 11.5859 18.4297 11.1953 18.4297 10.7773C18.4297 10.2148 18.125 9.69922 17.6484 9.42578V6.09375C17.6484 5.66406 18 5.3125 18.4297 5.3125C18.8594 5.3125 19.2109 5.66406 19.2109 6.09375V11.3984C19.2109 12.1133 19.0312 12.8242 18.6875 13.4492C18.5859 13.6406 18.6562 13.875 18.8437 13.9805C19.0351 14.082 19.2695 14.0117 19.375 13.8242C19.7773 13.082 19.9922 12.2422 19.9922 11.3984V6.09375C20 5.23047 19.3008 4.53125 18.4375 4.53125Z" fill="url(#paint3_linear)"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="4.26484" y1="5.89844" x2="15.7352" y2="5.89844" gradientUnits="userSpaceOnUse">
                        <stop offset="2.02456e-07" stopColor="#8116B2"/>
                        <stop offset="1" stopColor="#C32D54"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="5.96046e-07" y1="11.9531" x2="9.60937" y2="11.9531" gradientUnits="userSpaceOnUse">
                        <stop offset="2.02456e-07" stopColor="#8116B2"/>
                        <stop offset="1" stopColor="#C32D54"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear" x1="17.5391" y1="15" x2="18.3203" y2="15" gradientUnits="userSpaceOnUse">
                        <stop offset="2.02456e-07" stopColor="#8116B2"/>
                        <stop offset="1" stopColor="#C32D54"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear" x1="10.3906" y1="11.9531" x2="20" y2="11.9531" gradientUnits="userSpaceOnUse">
                        <stop offset="2.02456e-07" stopColor="#8116B2"/>
                        <stop offset="1" stopColor="#C32D54"/>
                    </linearGradient>
                </defs>
            </svg>
)
}